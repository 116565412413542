import styled from "styled-components";
import { LayoutBox } from "../Layout/OuterLayout";

export const PageInfo = styled(LayoutBox)`
padding:3.5rem 0;
border-bottom: 1px solid #E5EAF4;
.btn{
    @media screen and (max-width: 992px) {
    margin-top:24px;
    }

}

`;
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "./components/Layout";
import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Careers";
import JobDetails from "./pages/Careers/JobDetails";
import Home from "./pages/Home";
import ProjectList from "./pages/Portfolio/ProjectList";
import RequestQoute from "./pages/RequestQoute";
import Services from "./pages/Services";
import DeckFence from "./pages/Portfolio/DeckFence";
import Pergola from "./pages/Portfolio/Pergola";
import TreeMaintenance from "./pages/Portfolio/TreeMaintenance";
import ContactUs from "./pages/ContactUs";
import ScrolltoTop from "./ScrolltoTop";
import ReclaimingBackyard from "./pages/Portfolio/ReclaimingBackyard";
import YardTransformation from "./pages/Portfolio/YardTransformation";
import TermsandConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Sod from "./pages/Portfolio/Sod";
// import StoneFrontSteps from "./pages/Portfolio/StoneFrontSteps";

// import Routes from './Routes';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="terms-and-conditions" element={<TermsandConditions />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />

          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="pristine-services" element={<Services />} />
            <Route path="careers" element={<Careers />} />
            <Route path="careers/job-opening/:id" element={<JobDetails />} />
            <Route path="request-qoute" element={<RequestQoute />} />
            <Route path="featured-projects" element={<ProjectList />} />
            <Route path="featured-projects/deck-fence" element={<DeckFence />} />
            <Route path="featured-projects/pergola" element={<Pergola />} />
            <Route path="featured-projects/reclaiming-backyard" element={<ReclaimingBackyard />} />
            <Route path="featured-projects/Sod" element={<Sod />} />
            <Route
              path="featured-projects/tree-maintenance"
              element={<TreeMaintenance />}
            />
            <Route
              path="featured-projects/yard-transformation"
              element={<YardTransformation />}
            />
            {/* <Route
              path="featured-projects/stone-front-steps"
              element={<StoneFrontSteps />}
            /> */}
          </Route>
        </Routes>
        <ScrolltoTop />
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;

import React, { FC } from "react";
import HeadingBanner from "../../components/TopHeading";
import { MiddleSection } from "../../components/CommonStyles";
import { Col, Container, Row } from "react-bootstrap";
import { ContactForm } from "../../components/ContactUs/ContactForm";
import styled from "styled-components";
import { H4, SmallPara } from "../../components/Typography";

export const AddressSection = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 20px 50px;
  @media screen and (max-width: 600px) {
    padding: 16px;
  }
  .address-box {
    text-align: center;
    padding: 24px;
    border-bottom: 1px solid #d5d5d5;
  }

  .address-box:last-child {
    border: none;
  }

  .city {
    font-size: 26px;
  }
  .link {
    color: #0065f2;
    font-size: 20px;
    line-height: 24px;
    font-family: "Hiragino Mincho Pro";
    text-decoration: none;
    font-weight: 300;
  }
`;

const index: FC = () => {
  return (
    <>
      <HeadingBanner heading="Contact Us" />
      <MiddleSection>
        <Container>
          <Row className="gx-5">
            <Col xs="12" lg="6">
              <AddressSection>
                <div className="address-box">
                  <H4 text="Head Office, Ajax ON" className="city" mb={8} />
                  <SmallPara
                    text="381 Westney Rd S, Ajax, Ontario L1S 6M6, Canada"
                    mb={8}
                  />
                  <a href="tel:+416-737-8978" className="link">
                    Call Location: 416-737-8978
                  </a>
                </div>
                <div className="address-box">
                  <H4 text="Eastern Ontario Division" className="city" mb={8} />
                  <SmallPara text="PO Box 464 Brighton, ON K0K1H0" mb={8} />
                  <a href="tel:+613-218-6610" className="link">
                    Call Location: 613-218-6610
                  </a>
                </div>
                <div className="address-box">
                  <H4 text="Near North Division" className="city" mb={8} />
                  <SmallPara text="Huntsville, ON" mb={8} />
                  <a href="tel:(705) 349-8978" className="link">
                    Call Location: (705) 349-8978
                  </a>
                </div>
                <div className="address-box">
                  <H4 text="Capital Division" className="city" mb={8} />
                  <SmallPara text="Ottawa, ON" mb={8} />
                  <a href="tel:(613) 218-6610" className="link">
                    Call Location: (613) 218-6610  
                  </a>
                </div>
                <div className="address-box">
                  <H4 text="Kawarthas – Supply Depot" className="city" mb={8} />
                  <SmallPara
                    text="1622 Hwy 7, Lindsay, Ontario K9V 4R1, Canada"
                    mb={8}
                  />
                  <a href="tel:+705-799-7401" className="link">
                    Call Location: 705-799-7401
                  </a>
                </div>
                <div className="address-box">
                  <H4 text="Brighton – Supply Depot" className="city" mb={8} />
                  <SmallPara text="84 Sharp Road, Brighton ON" mb={8} />
                  <a href="tel:(613) 218-6610" className="link">
                    Call Location: (613) 218-6610  
                  </a>
                </div>
              </AddressSection>
            </Col>
            <Col xs="12" lg="6">
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </MiddleSection>
    </>
  );
};

export default index;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { SmallPara } from "../../components/Typography";
import { ProjectImage } from "../Portfolio/style";
import image1 from "../../assets/img/services/SnowIceManagement/1.jpg";
import image2 from "../../assets/img/services/SnowIceManagement/2.jpg";
import image3 from "../../assets/img/services/SnowIceManagement/3.jpg";
import image4 from "../../assets/img/services/SnowIceManagement/4.jpg";
import image6 from "../../assets/img/services/SnowIceManagement/5.png";
import image7 from "../../assets/img/services/SnowIceManagement/6.png";
import image8 from "../../assets/img/services/SnowIceManagement/7.png";
import image5 from "../../assets/img/services/4.png";

const SnowIceManagement = () => {
  return (
    <>
      <SmallPara
        mb={24}
        text="
        At PPM we specialize in year-round grounds maintenance. Our crews shift gears in the winter months to
        focus on snow and ice management. Our services includes snow clearance, ice maintenance and snow
        relocation. We have 55 plow trucks, tractors, loaders and salters on the roads during storm events. We
        have gained the coveted Smart Salt certification which means we will protect your property from
        slippery conditions but limit the damage otherwise to waterways and plant life from the overuse of de-icing materials."
        color="#424242"
      />
      <Row className="gy-4 justify-content-center">
        <Col xs="12" lg="6">
          <ProjectImage
            className="shortImg"
            style={{ backgroundImage: `url(${image5})` }}
          />
        </Col>
        {/* <Col xs="12" lg="6">
          <ProjectImage
            className="shortImg"
            style={{ backgroundImage: `url(${image2})` }}
          />
        </Col>
        <Col xs="12" lg="6">
          <ProjectImage
            className="shortImg"
            style={{ backgroundImage: `url(${image3})` }}
          />
        </Col>
        <Col xs="12" lg="6">
          <ProjectImage
            className="shortImg"
            style={{ backgroundImage: `url(${image4})` }}
          />
        </Col> */}
        {/* <Col xs="12" lg="6">
          <ProjectImage
            className="shortImg"
            style={{ backgroundImage: `url(${image1})` }}
          />
        </Col> */}
        <Col xs="12" lg="6">
          <ProjectImage
            className="shortImg"
            style={{ backgroundImage: `url(${image6})` }}
          />
        </Col>
        <Col xs="12" lg="6">
          <ProjectImage
            className="shortImg"
            style={{ backgroundImage: `url(${image7})` }}
          />
        </Col>
        <Col xs="12" lg="6">
          <ProjectImage
            className="shortImg"
            style={{ backgroundImage: `url(${image8})` }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SnowIceManagement;

import { Image } from "react-bootstrap";
import styled from "styled-components";
import contentBg from "../../assets/img/content-section-bg.png";

export const MiddleSection = styled.div`
  background-color: #f5f5f5;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${contentBg});
  padding: 56px 0 30px;
  background-size: 100% 420px;
  @media screen and (max-width: 600px) {
    padding: 32px 0;
    background-size: 100% 300px;
  }
`;

export const OuterBox = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 3rem;
  @media screen and (max-width: 992px) {
    padding: 1.5rem;
  }
  .buttonclass{
    font-weight: 700;
    min-width: 150px;
    font-size: 18px;
    line-height: 1;
    width: auto;
    border: 2px solid #0d6efd;
    padding: 16px;
    border-radius: 50px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
  }
`;

export const ImageClass = styled(Image)`
  width: 100%;
  border-radius: 8px;
`;

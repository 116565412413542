import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { H2, Para } from "../Typography";
import backArrow from "../../assets/img/backarrow.svg";
import headingbg from "../../assets/img/heading-bg.png";

const Headingbanner = styled.div`
  background: linear-gradient(121.82deg, #08426e -0.38%, #008cf7 138.98%);
  padding: 10px 0 0 0;

  .banner-col {
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${headingbg});
    height: 166px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
      height: auto;
      min-height: 130px;
    }
  }
  .headinglink {
    display: flex;
    margin: 50px 0;

    img {
      margin-right: 20px;
      margin-top: 5px;
      @media screen and (max-width: 600px) {
        margin-top: 5px;
        width: 15px;
      }
    }
  }
`;

interface Props {
  onClick?: () => void;
  heading?: string;
  linkHeading?: boolean;
  content?: string;
}

const HeadingBanner: FC<Props> = ({
  heading,
  linkHeading = false,
  content,
  onClick,
}) => {
  return (
    <Headingbanner>
      <Container>
        <Row>
          <Col>
            {linkHeading ? (
              <div className="headinglink" onClick={onClick}>
                <img src={backArrow} alt="back" />
                <H2 text={heading} color="#fff" />
              </div>
            ) : (
              <H2 text={heading} color="#fff" className="headinglink" />
            )}
          </Col>
          <Col className="banner-col">
            {content && <Para color="#fff" text={content} />}
          </Col>
        </Row>
      </Container>
    </Headingbanner>
  );
};

export default HeadingBanner;

import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  ImageClass,
  MiddleSection,
  OuterBox,
} from "../../components/CommonStyles";
import { SmallPara } from "../../components/Typography";
import image1 from "../../assets/img/projects/yard-transformation/1.jpg";
import image2 from "../../assets/img/projects/yard-transformation/2.jpg";
import image3 from "../../assets/img/projects/yard-transformation/3.jpg";
import image4 from "../../assets/img/projects/yard-transformation/4.jpg";
import image5 from "../../assets/img/projects/yard-transformation/5.jpg";
import image6 from "../../assets/img/projects/yard-transformation/6.jpg";
import image7 from "../../assets/img/projects/yard-transformation/7.jpg";
import HeadingBanner from "../../components/TopHeading";
import ContactSection from "../../components/ContactUs";
import { useNavigate } from "react-router-dom";

const YardTransformation: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeadingBanner
        heading="Yard Transformation"
        onClick={() => {
          navigate(-1);
        }}
        linkHeading
      />
      <MiddleSection>
        <Container>
          <OuterBox>
            <SmallPara
              mb={16}
              text="Our assignment on this cottage property has been complete.  The following has been finished by PPM employees:"
            />
            <ul>
              <li>Planted trees along the driveway</li>
              <li>Planted and soiled gardens</li>
              <li>Topsoil and seeded the whole property</li>
              <li>Installed and graded the gravel driveway</li>
              <li>Designed and Installed flag stone walkway and fire pit</li>
              <li>Built rock retaining wall on waters edge</li>
            </ul>
            <SmallPara
              mt={16}
              mb={24}
              text="Very satisfied customers at the end of this job."
            />

            <Row className="gy-4">
              <Col xs="12" md="6">
                <ImageClass src={image1} alt="Tree services" />
              </Col>
              <Col xs="12" md="6">
                <ImageClass src={image2} alt="Tree services" />
              </Col>
              <Col xs="12" md="6">
                <ImageClass src={image3} alt="Tree services" />
              </Col>
              <Col xs="12" md="6">
                <ImageClass src={image4} alt="Tree services" />
              </Col>
              <Col xs="12" md="6">
                <ImageClass src={image5} alt="Tree services" />
              </Col>
              <Col xs="12" md="6">
                <ImageClass src={image6} alt="Tree services" />
              </Col>
              <Col xs="12">
                <ImageClass src={image7} alt="Tree services" />
              </Col>
            </Row>
          </OuterBox>
          <ContactSection />
        </Container>
      </MiddleSection>
    </>
  );
};

export default YardTransformation;

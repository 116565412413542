import { FC } from "react";
import styled from "styled-components";
import CustomBadge from "../../components/Badges";
import PrimaryButton from "../../components/Button";
import { H3 } from "../../components/Typography";

const Joblistitem = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(8, 66, 110, 0.5);
  border-radius: 0 0 8px 8px;
  margin: 24px 0;

  .topsection {
    background: linear-gradient(121.82deg, #08426e -0.38%, #008cf7 138.98%);
    min-height: 112px;
    padding: 24px;
    display: flex;
    align-items: flex-start;
    border-radius: 0 0 8px 8px;
  }

  .bottomsection {
    padding: 24px;
    text-align: right;
  }

  .title {
    padding-bottom: 12px;
    color: #fff;
    width: 100%;
    // border-bottom: 1px solid #ffffff;
  }
`;

interface Props {
  onClick?: () => void;
  jobtitle?: string;
}

const JobItem: FC<Props> = ({ jobtitle, onClick }) => {
  return (
    <Joblistitem>
      <div className="topsection">
        <H3 text={jobtitle} className="title" />
        <CustomBadge varient="danger">CLOSED</CustomBadge>
      </div>
      <div className="bottomsection">
        <PrimaryButton
          text="See Details"
          onClick={onClick}
          color="primary"
          round
          variant="outlined"
        />
      </div>
    </Joblistitem>
  );
};

export default JobItem;

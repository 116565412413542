import { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import image1 from "../../assets/img/projects/backyard/1.jpg";
import image2 from "../../assets/img/projects/backyard/2.jpg";
import image3 from "../../assets/img/projects/backyard/3.jpg";
// import image4 from "../../assets/img/projects/backyard/4.jpg";
import image5 from "../../assets/img/projects/backyard/5.jpg";
import { MiddleSection, OuterBox } from "../../components/CommonStyles";
import ContactSection from "../../components/ContactUs";
import HeadingBanner from "../../components/TopHeading";
import { ProjectImage } from "./style";

const ReclaimingBackyard: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeadingBanner
        heading="Reclaiming a Backyard"
        onClick={() => {
          navigate(-1);
        }}
        linkHeading
      />
      <MiddleSection>
        <Container>
          <OuterBox>
            <Row>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg mb-24"
                  style={{ backgroundImage: `url(${image3})` }}
                >
                  <div className="caption">Before</div>
                </ProjectImage>
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg mb-24"
                  style={{ backgroundImage: `url(${image1})` }}
                >
                  <div className="caption">After</div>
                </ProjectImage>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg mb-24"
                  style={{ backgroundImage: `url(${image2})` }}
                >
                  <div className="caption">Before</div>
                </ProjectImage>
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg mb-24"
                  style={{ backgroundImage: `url(${image5})` }}
                >
                  <div className="caption">After</div>
                </ProjectImage>
              </Col>
            </Row>
          </OuterBox>
          <ContactSection />
        </Container>
      </MiddleSection>
    </>
  );
};

export default ReclaimingBackyard;

import * as yup from "yup";

export const contactformschema = yup.object({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().email().required("Email is required"),
  address: yup.string().required("Address is required"),
  availability_date: yup
    .date()
    .min(new Date(), "Invalid date")
    .typeError("Date is required"),
  start_time: yup.string().required("Start time is required"),
  end_time: yup.string().required("End time is required"),
  service_type: yup.string().required("Service type is required"),
  location: yup.string().required("location is required"),
});

export const requestformschema = yup.object({
  full_name: yup
    .string()
    .matches(
      /^[a-zA-Z\u00C0-\u00FF!@#$%^&*-_’',.]+(?:\s[a-zA-Z\u00C0-\u00FF!@#$%^&*-_’',.]+)+$/,
      "Please enter your full name"
    )
    .required("First name is required"),
  phone_number: yup
    .string()
    .matches(/^[0-9]{10}$/, "Must be exactly 10 digits and only numbers")
    // .length(10, "Invalid Phone number")
    .required("Phone is required"),
  email: yup.string().email().required("Email is required"),
  address: yup.string().required("Address is required"),
  service_type: yup.string().required("Service type is required"),
  size: yup.string().required("Size is required"),
  location: yup.string().required("location is required"),
  description: yup.string().required("Description is required"),
  approx_budget: yup.string(),
});

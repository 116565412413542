import { MouseEvent, ReactNode } from "react";
import styled from "styled-components";

export type Item<T> = T & { [key: string]: any };

export interface ResultsProps<T> {
  results: Item<T>[];
  onClick: Function;
  highlightedItem: number;
  setHighlightedItem: Function;
  setSearchString: Function;
  formatResult?: Function;
  maxResults: number;
  resultStringKeyName: string;
}

export default function Results<T>({
  results = [] as any,
  onClick,
  setSearchString,
  maxResults,
  resultStringKeyName = "name",
  highlightedItem,
  setHighlightedItem,
  formatResult,
}: ResultsProps<T>) {
  type WithStringKeyName = T & Record<string, unknown>;

  const formatResultWithKey = formatResult
    ? formatResult
    : (item: WithStringKeyName) => item[resultStringKeyName];

  const handleClick = (result: WithStringKeyName) => {
    onClick(result);
    setSearchString(result[resultStringKeyName]);
  };

  const handleMouseDown = ({
    event,
    result,
  }: {
    event: MouseEvent<HTMLLIElement>;
    result: WithStringKeyName;
  }) => {
    if (event.button === 0) {
      event.preventDefault();
      handleClick(result);
    }
  };

  if (results?.length <= 0) {
    return null;
  }

  return (
    <ResultsWrapper>
      {results.slice(0, maxResults).map((result, index) => (
        <li
          style={{ backgroundColor: highlightedItem === index ? "#eee" : "" }}
          onMouseEnter={() => setHighlightedItem({ index })}
          data-test="result"
          key={`rsa-result-${result.id}`}
          onMouseDown={(event) => handleMouseDown({ event, result })}
          onClick={() => handleClick(result)}
        >
          <div
            className="ellipsis"
            title={result[resultStringKeyName] as string}
          >
            {formatResultWithKey(result)}
          </div>
        </li>
      ))}
    </ResultsWrapper>
  );
}

const ResultsWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <StyledResults>
      <div className="line" />
      <ul>{children}</ul>
    </StyledResults>
  );
};

const StyledResults = styled.div`
  border: 1px solid #dfe1e5;

  &:hover {
    box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
  }
  &:active {
    box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
  }
  &:focus-within {
    box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
  }

  > div.line {
    border-top-color: #e8eaed;
    border-top-style: solid;
    border-top-width: 1px;

    margin-bottom: 0px;
    margin-left: 14px;
    margin-right: 20px;
    margin-top: 0px;

    padding-bottom: 4px;
  }

  > ul {
    list-style-type: none;
    margin: 0;
    padding: 0px 0 16px 0;

    > li {
      display: flex;
      align-items: center;
      padding: 4px 0 4px 0;

      > div {
        margin-left: 13px;
      }
    }
  }

  .ellipsis {
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components';
import { H3 } from '../../components/Typography'


export const ContentBox = styled.div`
`;

const TermsandConditions = () => {
  return (
    <ContentBox>
    <Container>
     <Row>
        <Col xs="12" className="text-center mt-5">
          <H3 text="Terms and Conditions" className='title' fontweight={600} mb={48}  />
        </Col>
     </Row>
    </Container>
    </ContentBox>
  )
}

export default TermsandConditions
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { H3 } from '../../components/Typography'
import { ContentBox } from '../TermsAndConditions'

const PrivacyPolicy = () => {
  return (
    <ContentBox>
    <Container>
    <Row>
       <Col xs="12" className="text-center mt-5">
         <H3 text="Privacy Policy" fontweight={600} mb={48} />
       </Col>
    </Row>
   </Container>
   </ContentBox>
  )
}

export default PrivacyPolicy
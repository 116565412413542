import React from "react";
import { Para } from "../../components/Typography";
import historyImage from "../../assets/img/Our-History.png";

const HistoryData = () => {
  return (
    <>
      <Para
        mb={24}
        text="Pristine Property Maintenance Limited was founded in 2005. Our company has aggressive goals of which we have not only been able to attain but exceed since our inception. We are team driven and therefore strive to promote new Crew Leaders from among our existing qualified pool of talent. As a result, our crews are driven to succeed – but it is not just simply handed to anyone, and it is certainly not an easy run! We have very strict guidelines that each of our employees follow and each of our Crew Leaders enforce. Professionalism is key for our success and as such we allow little room for disappointment or short-comings."
      />
      <img src={historyImage} alt="Our Drive" />
    </>
  );
};

export default HistoryData;

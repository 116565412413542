import React from "react";
import { Container, NavLink } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import previmage from "../../assets/img/prev.svg";
import nextimage from "../../assets/img/next.svg";

import OuterLayout from "../Layout/OuterLayout";
import { Chip, H2, H4 } from "../Typography";
import { projectList } from "../../utils/helper";
import { Link } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4.5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3.5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2.5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.5,
  },
};

const HeadingSection = styled.div`
  position: absolute;
  top: 0;
  left: 7.5%;

  @media screen and (max-width: 600px) {
    top: 15px;
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  .react-multi-carousel-list {
    margin-left: 7.5%;
    padding-top: 150px;
    @media screen and (max-width: 600px) {
      padding-top: 120px;
    }
  }
  .heading {
    @media screen and (max-width: 600px) {
      text-align: center;
    }
  }
  .react-multiple-carousel__arrow--right {
    right: 70px;
    background-image: url(${nextimage});
    @media screen and (max-width: 600px) {
      right: 20px;
    }
  }
  .react-multiple-carousel__arrow--right::before,
  .react-multiple-carousel__arrow--left::before {
    content: "";
  }
  .react-multiple-carousel__arrow--left {
    right: 150px;
    left: auto;
    background-image: url(${previmage});
    @media screen and (max-width: 600px) {
      right: 60px;
    }
  }
  .react-multiple-carousel__arrow {
    top: 70px;
    background-color: transparent;
    min-width: 56px;
    min-height: 56px;
    @media screen and (max-width: 600px) {
      min-width: 32px;
      min-height: 32px;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .imagebox {
    filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.25));
    height: 450px;
    border:1px solid #ddd;
    margin-right: 24px;
    position: relative;
    background-position: center;
    background-size: cover;
    border-radius: 6px;
    @media screen and (max-width: 992px) {
      height: 350px;
    }
    @media screen and (max-width: 600px) {
      height: 250px;
    }
  }
  .caption {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    padding: 60px 5px 20px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    @media screen and (max-width: 600px) {
      font-size: 18px;
    }
  }
`;

const ProjectSlider = () => {
  return (
    <OuterLayout>
      <Container fluid style={{ padding: 0 }}>
        <CarouselContainer>
          <HeadingSection>
            <Chip text="Our Portfolio" />
            <H2 text="Featured Projects" mt={24} className="heading" />
          </HeadingSection>
          <Carousel responsive={responsive} autoPlay={true} infinite={true}>
            {projectList.map((item, index) => {
              return (
                <NavLink
                  as={Link}
                  to={item.link}
                  className="imagebox"
                  style={{ backgroundImage: `url(${item.image})` }}
                  key={index}
                >
                  <H4 text={item.title} className="caption" color="#fff" />
                </NavLink>
              );
            })}
          </Carousel>
        </CarouselContainer>
      </Container>
    </OuterLayout>
  );
};

export default ProjectSlider;

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import OuterLayout from '../Layout/OuterLayout'
import { ContactForm } from './ContactForm'
import Content from './Content'

const ContactUsBox = styled.div`
background: #183B56;
border-radius: 8px;
box-sizing:border-box;
padding:3rem;
@media screen and (max-width: 992px) {
  padding: 1.5rem;
}
`

const ContactSection = () => {
  return (
    <OuterLayout>
     <Container>
     <ContactUsBox>
        <Row>
          <Col xs="12" lg="6">
            <Content />
          </Col>
          <Col xs="12" lg="6">
            <ContactForm />
          </Col>
        </Row>
    </ContactUsBox>
    </Container>
    </OuterLayout>
  )
}

export default ContactSection
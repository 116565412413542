import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  MiddleSection,
  OuterBox,
} from "../../components/CommonStyles";
import { SmallPara } from "../../components/Typography";
import image1 from "../../assets/img/services/Tree-Services/1.jpg";
import image2 from "../../assets/img/services/Tree-Services/2.jpg";
import image3 from "../../assets/img/services/Tree-Services/3.jpg";
import image4 from "../../assets/img/services/Tree-Services/4.jpg";
import HeadingBanner from "../../components/TopHeading";
import ContactSection from "../../components/ContactUs";
import { useNavigate } from "react-router-dom";
import { ProjectImage } from "./style";

const TreeMaintenance: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeadingBanner
        heading="Tree Maintenance"
        onClick={() => {
          navigate(-1);
        }}
        linkHeading
      />
      <MiddleSection>
        <Container>
          <OuterBox>
            <SmallPara
              mb={16}
              text="At PPM we offer a complete range of tree maintenance activities. "
              color="#424242"
            />
            <SmallPara
              mb={16}
              text="We are trained and equipped to provide industry standard pruning, removal, cabling and stump grinding. We can also provide the consulting services for any regulatory permits that may be required for these activities."
              color="#424242"
            />
            <SmallPara
              mb={24}
              text="Our plant health care services include specialized feeding programs that both feed the trees and improve soil quality.  We can renovate compacted soils with our air spade which can till soil without damaging roots or underground infrastructure.  We have a preventive treatment that mitigates the damaging affects of de-icing salts used through the winter, a major source of tree decline.  We can also develop programs to deal with insect and disease outbreaks."
              color="#424242"
            />
            <SmallPara
              mb={24}
              text="Our arborists plant large caliper trees to help renew landscapes and ensure their sustainability.  We can help make site specific recommendations for species that would work best in your landscape."
              color="#424242"
            />
            <SmallPara
              mb={24}
              text="
              Our certified arborists can develop a tailored program for tree care in your community or at your private residence.  They can advise on the proper mix of services that ensure better sustainability of the landscape."
              color="#424242"
            />
            <Row className="gy-4">
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg"
                  style={{ backgroundImage: `url(${image1})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg"
                  style={{ backgroundImage: `url(${image2})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg"
                  style={{ backgroundImage: `url(${image3})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg"
                  style={{ backgroundImage: `url(${image4})` }}
                />
              </Col>
            </Row>
          </OuterBox>
          <ContactSection />
        </Container>
      </MiddleSection>
    </>
  );
};

export default TreeMaintenance;

import { default as Fuse } from "fuse.js";
import {
  ChangeEvent,
  FC,
  FocusEvent,
  FocusEventHandler,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { debounce, defaultFuseOptions } from "../../utils";
import Results, { Item } from "./Results";
import SelectInput from "./SelectInput";

interface Props {
  id: string;
  items: any[];
  fuseOptions?: any;
  label?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  name?: string;
  type?: string;
  error?: string;
  placeholder?: string;
  maxResults?: number;
  value: string;
  onChange: any;
  onHover?: (result: any) => void;
  resultStringKeyName?: string;
  formatResult?: Function;
}

const inputDebounce = 200;

export const MAX_RESULTS = 10;

const SelectAutocomplete: FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      items,
      label,
      onFocus = () => { },
      name,
      placeholder,
      error,
      type,
      value = "",
      resultStringKeyName = "name",
      maxResults = MAX_RESULTS,
      fuseOptions = defaultFuseOptions,
      onHover = () => { },
      formatResult,
      onChange,
      ...rest
    },
    ref
  ) => {
    const [isSearchComplete, setIsSearchComplete] = useState<boolean>(false);

    const [results, setResults] = useState<any[]>([]);
    const [hasFocus, setHasFocus] = useState<boolean>(false);

    const [highlightedItem, setHighlightedItem] = useState<number>(-1);

    const options = { ...defaultFuseOptions, ...fuseOptions };

    const fuse = new Fuse(items, options);
    fuse.setCollection(items);

    const callOnSearch = (keyword: string) => {
      let newResults: any = [];

      keyword?.length > 0 && (newResults = fuseResults(keyword));

      setResults(newResults);
    };

    const fuseResults = (keyword: string) =>
      fuse
        .search(keyword, { limit: maxResults })
        .map((result) => ({ ...result.item }))
        .slice(0, maxResults);

    const handleOnSearch = useCallback(
      inputDebounce > 0
        ? debounce((keyword: string) => callOnSearch(keyword), inputDebounce)
        : (keyword: string) => callOnSearch(keyword),
      [items]
    );

    const handleSetSearchString = (e: ChangeEvent<HTMLInputElement>) => {
      const { target } = e;
      const keyword = target.value;

      onChange(keyword);
      handleOnSearch(keyword);

      if (isSearchComplete) {
        setIsSearchComplete(false);
      }
    };

    const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
      onFocus(event);
      setHasFocus(true);
    };

    const eraseResults = () => {
      setResults([]);
      setIsSearchComplete(true);
    };

    const handleBlur = () => {
      eraseResults();
      setHasFocus(false);
    };

    useEffect(() => {
      if (results.length === 0 && value.length === 0 && hasFocus) {
        setResults(items.slice(0, maxResults));
      }
    }, [results, value, hasFocus]);

    const handleSetHighlightedItem = ({
      index,
      event,
    }: {
      index?: number;
      event?: KeyboardEvent<HTMLInputElement>;
    }) => {
      let itemIndex = -1;

      const setValues = (index: number) => {
        setHighlightedItem(index);
        results?.[index] && onHover(results[index]);
      };

      if (index !== undefined) {
        setHighlightedItem(index);
        results?.[index] && onHover(results[index]);
      } else if (event) {
        switch (event.key) {
          case "Enter":
            if (results.length > 0 && results[highlightedItem]) {
              event.preventDefault();
              onChange(results[highlightedItem][resultStringKeyName]);
            }
            setHighlightedItem(-1);
            eraseResults();
            break;
          case "ArrowUp":
            event.preventDefault();
            itemIndex =
              highlightedItem > -1 ? highlightedItem - 1 : results.length - 1;
            setValues(itemIndex);
            break;
          case "ArrowDown":
            event.preventDefault();
            itemIndex =
              highlightedItem < results.length - 1 ? highlightedItem + 1 : -1;
            setValues(itemIndex);
            break;
          default:
            break;
        }
      }
    };

    const handleOnClick = (result: Item<any>) => {
      eraseResults();
      onChange(result[resultStringKeyName]);
      setHighlightedItem(0);
    };

    return (
      <StyledReactSearchAutocomplete>
        <SelectInput
          label={label}
          id={id}
          type={"text"}
          name={name}
          placeholder={placeholder}
          searchString={value}
          setSearchString={handleSetSearchString}
          onFocus={handleOnFocus}
          eraseResults={eraseResults}
          setHighlightedItem={handleSetHighlightedItem}
          handleBlur={handleBlur}
          error={error}
          ref={ref}
          {...rest}
        />
        <div className="wrapper">
          <Results
            results={results}
            onClick={handleOnClick}
            setSearchString={onChange}
            maxResults={maxResults}
            resultStringKeyName={resultStringKeyName}
            formatResult={formatResult}
            highlightedItem={highlightedItem}
            setHighlightedItem={handleSetHighlightedItem}
          />
        </div>
      </StyledReactSearchAutocomplete>
    );
  }
);

const StyledReactSearchAutocomplete = styled.div`
  position: relative;

  .form-group {
    margin-bottom: 0px;
  }

  .wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;

    background-color: #fff;
    color: #212121;

    font-size: 16px;
    font-family: Arial;

    z-index: 1;
  }
`;

export default SelectAutocomplete;

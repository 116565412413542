import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { SmallPara } from '../../components/Typography'
import image1 from "../../assets/img/services/Fertilization-Weed-Control-Services/1.jpg";
import image2 from "../../assets/img/services/3.png";
import { ProjectImage } from '../Portfolio/style';

const FertilizationWeedControlServices = () => {
  return (
    <>
    <SmallPara
        mb={24}
        text="
        At PPM we recommend our full fertilizer program of environment and pet friendly fertilizers.  Our goal is to grow a robust and thick lawn that will help limit most unwanted pests and weeds.  Our program can include aeration to reduce compaction; overseeding with insect and disease  resistant grass types; and dethatching to the limit the habitat for pests and diseases.  We can also provide weed control for the lawn with organically registered products when required.  Similar safe products can be used to clear weeds from hard surfaces.
        "
        color="#424242"
      />
        <Row className="gy-4">
        <Col xs="12" lg="6">
          <ProjectImage
                  className="shortImg mb-24"
                  style={{ backgroundImage: `url(${image1})` }}
                />
        </Col>
        <Col xs="12" lg="6">
          <ProjectImage
                  className="shortImg mb-24"
                  style={{ backgroundImage: `url(${image2})` }}
                />
        </Col>
      </Row>
    </>
  )
}

export default FertilizationWeedControlServices
import React from "react";
import { SmallPara } from "../../components/Typography";
// import image1 from "../../assets/img/services/Property-Maintenance/image1.jpg";
// import image2 from "../../assets/img/services/Property-Maintenance/image2.jpg";
// import image3 from "../../assets/img/services/Property-Maintenance/image3.jpg";
import image4 from "../../assets/img/services/Property-Maintenance/image4.png";
import image5 from "../../assets/img/services/Property-Maintenance/image5.png";
import image6 from "../../assets/img/services/Property-Maintenance/image6.png";
import { Col, Row } from "react-bootstrap";
import { ProjectImage } from "../Portfolio/style";

const PropertyMaintenance = () => {
  return (
    <>
      <SmallPara
        mb={24}
        text="
        PPM has the manpower, equipment and expertise to provide full service property maintenance.  We can maintain large corporate or industrial sites, condominiums, small business or residential landscapes.  We can design a program that suits your needs with lawn mowing, seasonal cleanups, shrub pruning and/or garden maintenance. Our maintenance crews can coordinate with all our other divisions to deliver high standards of property care."
        color="#424242"
      />

      <Row>
        {/* <Col xs="12" lg="6">
          <ProjectImage
            className="longImg"
            style={{ backgroundImage: `url(${image1})` }}
          />
        </Col>
        <Col xs="12" lg="6">
          <ProjectImage
            className="shortImg mb-24"
            style={{ backgroundImage: `url(${image2})` }}
          />
          <ProjectImage
            className="shortImg"
            style={{ backgroundImage: `url(${image3})` }}
          />
        </Col> */}

        <Col xs="12" lg="6">
          <ProjectImage
            className="longImg"
            style={{ backgroundImage: `url(${image5})` }}
          />
        </Col>
        <Col xs="12" lg="6">
          <ProjectImage
            className="shortImg mb-24"
            style={{ backgroundImage: `url(${image4})` }}
          />
          <ProjectImage
            className="shortImg"
            style={{ backgroundImage: `url(${image6})` }}
          />
        </Col>
      </Row>
    </>
  );
};

export default PropertyMaintenance;

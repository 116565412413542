import React, { FC, useMemo } from "react";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

interface StyleProps {
  round?: string;
}

export const CustomButton = styled(Button)<StyleProps>`
  padding: 16px;
  font-weight: 700;
  min-width: 150px;
  font-size: 18px;
  line-height: 1;
  width: ${(props: StyleProps) => (props.round ? "auto" : "100%")};
  border-width: ${(props: StyleProps) => (props.round ? "2px" : "")};
  border-radius: ${(props: StyleProps) => (props.round ? "50px" : "5px")};
  @media screen and (max-width: 600px) {
    padding: 12px;
    font-size: 16px;
  }
`;

interface Props {
  text: string;
  variant?: "outlined" | "contained";
  color?: "primary" | "light";
  round?: boolean;
  type?: string;
  onClick?: () => void;
}

const PrimaryButton: FC<Props> = ({
  text,
  variant = "contained",
  color = "primary",
  round = false,
  type,
  onClick,
  ...rest
}) => {
  const btnVariant = useMemo(() => {
    switch (variant) {
      case "outlined":
        return `outline-${color}`;
      case "contained":
        return color;
    }
  }, [variant, color]);

  return (
    <CustomButton
      variant={btnVariant}
      round={round}
      {...rest}
      type={type}
      onClick={onClick}
    >
      {text}
    </CustomButton>
  );
};

export default PrimaryButton;

import { ChangeEventHandler, FC, FocusEventHandler } from "react";
import { Form } from "react-bootstrap";
import { InputTextStyle } from '../FormFields/InputText';

interface Props {
  id: string;
  label?: string;
  name?: string;
  type?: string;
  error?: string;
  placeholder?: string;
  searchString: string;
  onFocus: FocusEventHandler<HTMLInputElement>;
  eraseResults: Function;
  setSearchString: ChangeEventHandler<HTMLInputElement>;
  handleBlur: FocusEventHandler<HTMLInputElement> ;
  setHighlightedItem: Function;
  ref: any;
}

const SelectInput: FC<Props> = ({
  label,
  id,
  type,
  name,
  placeholder,
  searchString,
  setSearchString,
  onFocus,
  eraseResults,
  handleBlur,
  setHighlightedItem,
  error,
  ref,
  ...rest
}) => {
  return (
    <InputTextStyle>
    <Form.Group className="form-group" controlId={id}>
      <Form.Label className={label ? "" : "invisible"}>{label}</Form.Label>
      <Form.Control
        type={type}
        placeholder={placeholder}
        name={name}
        value={searchString}
        ref={ref}
        onChange={setSearchString}
        onFocus={onFocus}
        onBlur={handleBlur}
        onKeyDown={(event) => setHighlightedItem({ event })}
        {...rest}
      />
      <Form.Text className="error-text">{error}</Form.Text>
    </Form.Group>
    </InputTextStyle>
  );
};

export default SelectInput;

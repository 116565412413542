import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../Button";
import { LayoutBox } from "../Layout/OuterLayout";
import { H2, Para } from "../Typography";
import ServicesItem from "./ServicesItem";

const Services = () => {
  const navigate = useNavigate();
  return (
    <LayoutBox isBackgroundGrey={true}>
      <Container>
        <Row className="justify-content-md-center text-center">
          <Col xs lg="7">
            <H2 text="Our Services" mb={28} />
            <Para
              color="#5A7184"
              mb={24}
              text="Do you have issues with the maintenance of your commercial, residential or municipal properties? If you’re looking for a solution to keep your property at its best, we can help. From landscaping and lawn care to deck/fence repairs and snow removal, our team can provide 24/7 care to ensure that your grounds and building exteriors are safe and make the right impression."
            />
            <PrimaryButton
              text="Learn more"
              onClick={() => navigate("/pristine-services")}
              color="primary"
              round
              variant="outlined"
            />
          </Col>
        </Row>
        <ServicesItem />
      </Container>
    </LayoutBox>
  );
};

export default Services;

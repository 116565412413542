import { FC } from "react";
import {
  H1Typography,
  H2Typography,
  H3Typography,
  H4Typography,
  Paratext,
  H5Typography,
  TypoProps,
  Smalltext,
  Chiptext,
  SmallParatext,
} from "./style";

export const H1: FC<TypoProps> = ({ text, ...CssProps }) => {
  return <H1Typography {...CssProps}>{text}</H1Typography>;
};

export const H2: FC<TypoProps> = ({ text, ...CssProps }) => {
  return <H2Typography {...CssProps}>{text}</H2Typography>;
};

export const H3: FC<TypoProps> = ({ text, ...CssProps }) => {
  return <H3Typography {...CssProps}>{text}</H3Typography>;
};

export const H4: FC<TypoProps> = ({ text, ...CssProps }) => {
  return <H4Typography {...CssProps}>{text}</H4Typography>;
};

export const Para: FC<TypoProps> = ({ text, ...CssProps }) => {
  return <Paratext {...CssProps}>{text}</Paratext>;
};

export const SmallPara: FC<TypoProps> = ({ text, ...CssProps }) => {
  return <SmallParatext {...CssProps}>{text}</SmallParatext>;
};

export const H5: FC<TypoProps> = ({ text, ...CssProps }) => {
  return <H5Typography {...CssProps}>{text}</H5Typography>;
};

export const SmallHeading: FC<TypoProps> = ({ text, ...CssProps }) => {
  return <Smalltext {...CssProps}>{text}</Smalltext>;
};

export const Chip: FC<TypoProps> = ({ text }) => {
  return <Chiptext>{text}</Chiptext>;
};

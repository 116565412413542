import React, { FC } from "react";
import { Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  options: Array<any>;
}

const TabsStyle = styled(Tabs)`
  justify-content: center;
  border: none;
  .nav-link {
    border: none;
    font-size: 30px;
    color: #000000;
    font-family: "Hiragino Mincho Pro";
    padding: 0 0 5px !important;
    margin: 0 26px;
    @media screen and (max-width: 600px) {
      font-size: 24px;
      margin: 8px 24px;
    }
    &.active {
      color: #183b56;
      border-bottom: 3px solid #183b56;
    }
  }
  .tabcontent {
    margin-top: 48px;
  }
`;

const Tabcontent = styled.div`
  margin-top: 48px;
`;

const CustomTabs: FC<Props> = ({ options }) => {
  return (
    <TabsStyle>
      {options.map((data, index) => {
        const { title, eventKey, tabcontent } = data;
        return (
          <Tab eventKey={eventKey} key={index} title={title}>
            <Tabcontent>{tabcontent}</Tabcontent>
          </Tab>
        );
      })}
    </TabsStyle>
  );
};

export default CustomTabs;


export const parseError: any = (errRes: any) => {
  let errorMsg = "";
  if (typeof errRes === "object") {
    const errorArr = errRes && Object.values(errRes);
    if (errorArr?.length > 1) {
      errorMsg = errorArr?.map((error: string) => String(error))?.join(", ");
    } else {
      errorMsg = String(Object.values(errRes));
    }
  } else {
    errorMsg = errRes;
  }
  return errorMsg;
};

const getCurrentYear = () => {
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-CA", {
    year: "numeric",
    timeZone: "America/Toronto",
  });
  return formattedDate;
};

export default getCurrentYear;

export function debounce(func: Function, wait: number, immediate?: boolean) {
  let timeout: NodeJS.Timeout | null;

  return function (this: any) {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    if (immediate && !timeout) func.apply(context, args);

    timeout && clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const defaultFuseOptions: any = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  minMatchCharLength: 1,
  keys: ["name"],
};

export { defaultFuseOptions };

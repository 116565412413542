import React, { FC } from "react";
import HeadingBanner from "../../components/TopHeading";
import { MiddleSection, OuterBox } from "../../components/CommonStyles";
import { H3 } from "../../components/Typography";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import JobItem from "./JobItem";
import { jobList } from "../../utils/helper";


const Careers: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeadingBanner
        heading="Careers"
        content="Pristine Property is always looking for top quality people to join our team. If you are committed to customer service and excellent worth ethic, apply today."
      />
      <MiddleSection>
        <Container>
          <OuterBox>
            <H3 text="Job Listings" />
            <Row>
            {jobList.map((item, index) => {
              return (
                <Col xs="12" md="6">
                <JobItem 
                jobtitle={item.jobtitle}
                onClick={() => navigate(`/careers/job-opening/${item.jobId}`)}
                />
                </Col>
                );
              })}
            </Row>
          </OuterBox>
        </Container>
      </MiddleSection>
    </>
  );
};

export default Careers;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { ImageClass } from "../../components/CommonStyles";
import { SmallPara } from "../../components/Typography";
import image1 from "../../assets/img/services/Waste-Management-Services/1.jpg";
import image2 from "../../assets/img/services/Waste-Management-Services/2.jpg";
import image3 from "../../assets/img/services/Waste-Management-Services/3.png";
import image4 from "../../assets/img/services/Waste-Management-Services/4.jpg";
import { ProjectImage } from "../Portfolio/style";

const WasteManagementServices = () => {
  return (
    <>
      <SmallPara
        mb={16}
        text="
        PPM offers services such as garbage collection and disposal, bin placement on garbage, green bin, and recycling days, dumpster drop off and pickup services, material delivery, and daily litter pickup.
        "
        color="#424242"
      />
      <SmallPara
        mb={24}
        text="
        We service all commercial, residential and industrial sites throughout the GTA.  This division is also able to power-wash and sanitize your garbage room and bins.
        "
        color="#424242"
      />
      <Row className="gy-4">
        <Col xs="12" lg="6">
          <Row className="gy-4">
            <Col xs="12">
              <ImageClass src={image1} alt="Tree services" />
            </Col>
            <Col xs="12">
              <ImageClass src={image3} alt="Tree services" />
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="6">
          <ImageClass src={image2} alt="Tree services" />
        </Col>
        <Col xs="12">
          <ProjectImage
            className="normalimg"
            style={{ backgroundImage: `url(${image4})` }}
          />
        </Col>
      </Row>
    </>
  );
};

export default WasteManagementServices;

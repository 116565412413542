import React from "react";
import styled from "styled-components";
import { Para, SmallHeading } from "../../components/Typography";
import { crewList } from "../../utils/helper";

export const CrewOuter = styled.div`
  padding-right: 30px;
  overflow: auto;
  max-height: 750px;
  @media screen and (max-width: 600px) {
    max-height: 100%;
    padding-right: 0;
  }

  .crew-details {
    margin-bottom: 30px;
    @media screen and (max-width: 600px) {
      flex-direction:column;
      text-align: center;
    }
  }
  
  .crewImage{
    height:265px;
    margin-right: 32px;
    width:200px;
    min-width:200px;
    max-width:200px;
    background:#e5e5e5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 8px;
    @media screen and (max-width: 600px) {
      margin-right: 0;
      margin-bottom:20px;
    }
  }
`;



const CrewData = () => {
  return (
    <>
      <CrewOuter>
        {crewList.map((item) => {
          return (
            <div className="crew-details d-flex align-items-center">
              <div className="crewImage" style={{backgroundImage: `url(${item.image})`}} />
              <div>
                <Para text={item.name} />
                <SmallHeading text={item.subheading} />
                <SmallHeading text={item.details} />
              </div>
            </div>
          );
        })}

      </CrewOuter>
    </>
  );
};

export default CrewData;

export const API_URL = process.env.REACT_APP_BASE_API_URL

export const CONTACT_API_URL = API_URL+'/schedule/book'

export const CREATE_QOUTE_API_URL = API_URL+'/quote/request'

export const Z_INDEX = {
    background: 0,
    content: 1,
    header: 2,
    buttons: 3,
    modal: 10,
    overlay: 20,
  };
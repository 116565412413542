import React, { FC, forwardRef } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

export const InputTextStyle = styled.div`
  .form-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #5a7184;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 20px;
  }
  .error-text {
    color: red;
    margin-top: 6px;
    display: block;
  }
  .form-control {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding: 12px;
    font-family: "Open Sans", sans-serif;
    margin-top: 8px;
    border: 1px solid #c3cad9;
    border-radius: 8px;

    ::placeholder {
      color: #959ead;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #959ead;
    }

    ::-ms-input-placeholder {
      color: #959ead;
    }
  }
`;

interface Props {
  id: string;
  label?: string;
  placeholder?: string;
  name?: string;
  type?: string;
  error?: string;
  as?: any;
  rows?: number;
}
export const InputText: FC<Props> = forwardRef(
  (
    { id, label, placeholder, name, type, error, rows, as, ...rest },
    ref: any
  ) => {
    return (
      <InputTextStyle>
        <Form.Group className="form-group" controlId={id}>
          <Form.Label className={label ? "" : "invisible"}>{label}</Form.Label>
          <Form.Control
            size="lg"
            type={type}
            placeholder={placeholder}
            name={name}
            ref={ref}
            {...rest}
            as={as}
            rows={rows}
            min={new Date().toISOString().split('T')[0]} 
          />
          <Form.Text className="error-text">{error}</Form.Text>
        </Form.Group>
      </InputTextStyle>
    );
  }
);



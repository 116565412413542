import React from "react";
import { Col, Row } from "react-bootstrap";
import { SmallPara } from "../../components/Typography";
import { ProjectImage } from "../Portfolio/style";
import image1 from '../../assets/img/services/Hardscape/1.jpg'
import image2 from '../../assets/img/services/Hardscape/2.jpg'

const HardscapeConstruction = () => {
  return (
    <>
      <SmallPara
        mb={24}
        text="
      At PPM we can both design and install complex hardscaping features. A wide range of
features, including decks, fences, patios, steps, pergolas, gates, sheds, etc. can all be installed
by our skilled teams. We use industry standard procedures and materials to ensure quality
results. Our team leaders can sit with you to learn your needs and wants, advise you on the
best materials and provide you with designs customized to your landscape. If you already have
a design, we can also help you execute it with same professional standards."
        color="#424242"
      />
       <Row className="gy-4">
        <Col xs="12" lg="6">
          <ProjectImage
                  className="normalimg"
                  style={{ backgroundImage: `url(${image1})` }}
                />
        </Col>
        <Col xs="12" lg="6">
          <ProjectImage
                  className="normalimg"
                  style={{ backgroundImage: `url(${image2})` }}
                />
        </Col>
      </Row>
    </>
  );
};

export default HardscapeConstruction;

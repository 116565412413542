import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MiddleSection, OuterBox } from "../../components/CommonStyles";
import { ProjectImage } from "./style";
import image2 from "../../assets/img/projects/pergola/2.png";
import image3 from "../../assets/img/projects/pergola/3.png";
import image4 from "../../assets/img/projects/pergola/4.png";
import image5 from "../../assets/img/projects/pergola/5.png";
import HeadingBanner from "../../components/TopHeading";
import ContactSection from "../../components/ContactUs";
import { useNavigate } from "react-router-dom";

const Pergola: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeadingBanner
        heading="Pergola"
        onClick={() => {
          navigate(-1);
        }}
        linkHeading
      />
      <MiddleSection>
        <Container>
          <OuterBox>
            <Row>
              <Col xs="12" md="6">
                <ProjectImage
                  className="shortImg mb-24"
                  style={{ backgroundImage: `url(${image2})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="shortImg mb-24"
                  style={{ backgroundImage: `url(${image3})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="shortImg mb-24"
                  style={{ backgroundImage: `url(${image4})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="shortImg"
                  style={{ backgroundImage: `url(${image5})` }}
                />
              </Col>
            </Row>
          </OuterBox>
          <ContactSection />
        </Container>
      </MiddleSection>
    </>
  );
};

export default Pergola;

import { FC, ReactNode } from "react";
import Alert from "react-bootstrap/Alert";

interface Props {
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "dark"
    | "light";
  children: ReactNode;
  icon?: ReactNode;
}

const CustomAlert: FC<Props> = ({ variant, children, icon }) => {
  return (
    <Alert style={{ display: "flex" }} key={variant} variant={variant}>
      {icon}
      {children}
    </Alert>
  );
};

export default CustomAlert;

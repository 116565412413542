import React from "react";
import styled from "styled-components";
import { H2, H5, Para } from "../Typography";

const ContentBox = styled.div`
  padding-right: 50px;
  margin-bottom: 40px;
  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
  .dashedline {
    margin: 24px 0;
    border: 1px dashed #b3bac5;
    width: 60%;
  }
  .link {
    font-size: 16px;
    line-height: 27px;
    color: #b3bac5;
    display: block;
    text-decoration: none;
  }
  .labeltext {
    font-family: "Hiragino Mincho Pro";
  }
`;

const Content = () => {
  return (
    <ContentBox>
      <H2 text="Talk to us about your project" color="#fff" mb={24} />
      <Para
        text="We would love to hear about your project and discuss how we can help. Please fill the form to schedule a call."
        color="#B3BAC5"
      />
      {/* <hr className="dashedline" style={{}} /> */}
      {/* <H5
        text="Any problem about your booking?"
        color="#FAFAFB"
        className="labeltext"
      />
      <H5
        text="Contact us directly:"
        color="#FAFAFB"
        mb={24}
        className="labeltext"
      /> */}
      {/* <a href="tel:+(416) 737-8978" className="link">
        (416) 737-8978
      </a> */}
      {/* <a href="mailto:info@pristineproperty.ca" className="link">
        info@pristineproperty.ca
      </a> */}
    </ContentBox>
  );
};

export default Content;

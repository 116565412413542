import React, { FC } from "react";
import styled from "styled-components";

interface StyleProps {
  isBackgroundGrey?: boolean;
}

export const LayoutBox = styled.div`
  padding: 3.5rem 0;
  background: ${(props: StyleProps) =>
    props.isBackgroundGrey ? "#F4FBFF" : ""};
  @media screen and (max-width: 600px) {
    padding: 2rem 0;
  }

  .mob-center {
    @media screen and (max-width: 600px) {
      text-align: center;
    }
  }
`;

interface Props {
  children?: any;
  isBackgroundGrey?: boolean;
}

const OuterLayout: FC<Props> = ({ children, isBackgroundGrey }) => {
  return <LayoutBox isBackgroundGrey={isBackgroundGrey}>{children}</LayoutBox>;
};

export default OuterLayout;

import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { LayoutBox } from "../Layout/OuterLayout";
import { Chip, H2, Para, SmallHeading } from "../Typography";
import Image1 from "../../assets/img/Image1.png";
import Image2 from "../../assets/img/Image2.png";
import styled from "styled-components";

const OurAdvantagesOuter = styled.div`
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  .heading {
    margin: 60px 0;
    @media screen and (max-width: 767px) {
      margin: 30px 0;
    }
  }
  img {
    border-radius: 8px;
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
  .img-class {
    position: relative;
  }
  .listitem {
    font-family: "Hiragino Mincho Pro";
  }
  .badge {
    position: absolute;
    background: #1565d8;
    border-radius: 8px;
    padding: 24px;
    bottom: -55px;
    right: 24px;
    @media screen and (max-width: 767px) {
      bottom: -15px;
    }
  }
`;

export const OurAdvantages = () => {
  return (
    <OurAdvantagesOuter>
      <LayoutBox>
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" lg="9">
              <Chip text="Our Advantages" />
              <Row className="justify-content-around">
                <Col xs="12" md="6">
                  <H2 text="Why Pristine" className="heading" />
                  <div className="img-class">
                    <Image src={Image1} alt="Why Pristine" fluid={true} />
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="img-class">
                    <Image src={Image2} alt="Why Pristine" fluid={true} />
                    <div className="badge">
                      <p>Since</p>
                      <h3>2005</h3>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-around mt-5">
                <Col xs="12" md="4">
                  <Para
                    text="01 Versatility"
                    color="#183B56"
                    mb={16}
                    textTransform="uppercase"
                    className="listitem"
                  />
                  <SmallHeading
                    text="PPM has a vast amount of employees with years of experience.
                We take on all jobs big and small"
                    color="#5A7184"
                    mb={24}
                  />
                </Col>
                <Col xs="12" md="4">
                  <Para
                    text="02 Trained staff"
                    color="#183B56"
                    mb={16}
                    textTransform="uppercase"
                    className="listitem"
                  />
                  <SmallHeading
                    text="PPM staff members are highly trained in many fields"
                    color="#5A7184"
                    mb={24}
                  />
                </Col>
                <Col xs="12" md="4">
                  <Para
                    text="03 HIGH QUALITY"
                    color="#183B56"
                    mb={16}
                    textTransform="uppercase"
                    className="listitem"
                  />
                  <SmallHeading
                    text="Here at PPM we hold ourselves to the highest of standards to make sure we produce quality work.  We work as a team and walk the extra mile to make sure each customer is satisfied with our finished products"
                    color="#5A7184"
                    mb={24}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </LayoutBox>
    </OurAdvantagesOuter>
  );
};

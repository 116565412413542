import styled from "styled-components";

export interface TypoProps {
  link?: any;
  title?: string;
  className?: string;
  text?: any;
  required?: boolean;
  onClick?: () => void;
  ml?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  color?: string;
  textTransform?: string;
  fontweight?: number;
}

export const commonStyle = (props: TypoProps) => `
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: ${props.color || "#000"};
  margin-left: ${props.ml || 0}px;
  margin-top: ${props.mt || 0}px;
  margin-right: ${props.mr || 0}px;
  margin-bottom:${props.mb || 0}px;
  font-weight:${props.fontweight || 400};
  text-transform:${props.textTransform || 'initial'};
`;

export const H1Typography = styled.h1<TypoProps>`
  ${(props) => commonStyle}
  font-size: 56px;
  line-height: 68px;
  font-family: 'Hiragino Mincho Pro';
  @media screen and (max-width: 600px) {
    font-size: 32px;
    line-height: 42px;
  }
`;

export const H2Typography = styled.h2<TypoProps>`
  color:#183B56;
  ${(props) => commonStyle}
  font-size: 48px;
  line-height: 60px;
  font-family: 'Hiragino Mincho Pro';
  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const H3Typography = styled.h3<TypoProps>`
  ${(props) => commonStyle}
  font-size: 30px;
  font-family: 'Hiragino Mincho Pro';
  @media screen and (max-width: 600px) {
    font-size: 22px;
  }
`;

export const H4Typography = styled.h4<TypoProps>`
  ${(props) => commonStyle}
  font-size: 24px;
  line-height: 1.4;
  font-family: 'Hiragino Mincho Pro';
  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 32px;
  }

`;

export const H5Typography = styled.h5<TypoProps>`
  ${(props) => commonStyle(props)}
  font-size: 20px;
  line-height: 28px;
`;

export const Paratext = styled.p<TypoProps>`
  ${(props) => commonStyle(props)}
  font-size: 18px;
  line-height: 32px;
  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const SmallParatext = styled.p<TypoProps>`
  ${(props) => commonStyle(props)}
  font-size: 16px;
  line-height: 28px;
`;


export const Smalltext = styled.p<TypoProps>`
  ${(props) => commonStyle(props)}
  font-size: 14px;
  line-height: 24px;
`;


export const Chiptext = styled.span`
background: #ebf8f3;
border-radius: 50px;
font-weight: 600;
font-size: 14px;
line-height: 20px;
color:#36B37E;
padding:6px 24px;
`;








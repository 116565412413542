import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Para } from "../Typography";
import { ServiceimageList } from "../../utils/helper";
import { useNavigate } from "react-router-dom";

export const ServiceImageBox = styled.div`
  text-align: center;
  margin-top: 32px;
  filter: drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  
  .image {
    width: 100%;
    height: 200px;
    background-position: center;
    border-radius: 8px 8px 0 0;
    background-size: cover;
  }
  .caption {
    background: #fff;
    padding: 18px;
    border-radius: 0 0 8px 8px;
  }
`;

const ServicesItem: FC = () => {
  const navigate = useNavigate();
  return (
    <Row className="justify-content-center">
      {ServiceimageList.map((item, index) => {
        return (
          <Col xs="12" md="6" lg="4" key={index}>
              <ServiceImageBox onClick={()=> navigate(`/pristine-services?tab=${item.eventKey}`)}>
                <div
                  className="image"
                  style={{ backgroundImage: `url(${item.image})` }}
                ></div>
                <div className="caption">
                  <Para text={item.title} color="#5A7184" />
                </div>
              </ServiceImageBox>
          </Col>
        );
      })}
    </Row>
  );
};

export default ServicesItem;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../Button";
import { H1, Para } from "../Typography";
import { BannerSection } from "./style";

const MastHead = () => {
  const navigate = useNavigate();

  return (
    <BannerSection>
      <Container>
        <div className="logoheading"></div>
        <Row className="content">
          <Col xs md="8" lg="7" className="zIndex">
            <H1
              text="Shape Up Your Property This Spring & Summer"
              color="#fff"
              mb={24}
              fontweight={600}
            />
            <Para
              text="Pristine Property Maintenance Ltd. is a mid-sized company with
            years of experience in property maintenance & year round services.
            Contact us below for a quote"
              color="#fff"
              mb={24}
            />
            <div className="gap-4 d-flex">
              <PrimaryButton
                text="Contact Us"
                onClick={() => navigate("/contact-us")}
                color="light"
                round
              />
              <PrimaryButton
                text="Know More"
                onClick={() => navigate("/about-us")}
                color="light"
                round
                variant="outlined"
              />
            </div>
          </Col>
        </Row>
        <div className="mastheadimg"></div>
      </Container>
    </BannerSection>
  );
};

export default MastHead;

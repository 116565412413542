import React, { FC } from "react";
import HeadingBanner from "../../components/TopHeading";
import { MiddleSection, OuterBox } from "../../components/CommonStyles";
import { Col, Container, Row } from "react-bootstrap";
import { H3} from "../../components/Typography";
import RequestForm from "./RequestForm";


const index: FC = () => {
  return (
    <>
      <HeadingBanner heading="Request a Quote" />
      <MiddleSection>
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" lg="10">
              <OuterBox>
                <H3
                  text="Fill Out The Form To Help Us Generate A Quote For You"
                  color="#183B56"
                  mb={30}
                />
               <RequestForm />
              </OuterBox>
            </Col>
          </Row>
        </Container>
      </MiddleSection>
    </>
  );
};

export default index;

import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MiddleSection, OuterBox } from "../../components/CommonStyles";
import { SmallPara } from "../../components/Typography";
import HeadingBanner from "../../components/TopHeading";
import ContactSection from "../../components/ContactUs";
import { useNavigate } from "react-router-dom";
import { ProjectImage } from "./style";
import image1 from "../../assets/img/projects/SOD/before1.jpg";
import image2 from "../../assets/img/projects/SOD/after1.jpg";
import image3 from "../../assets/img/projects/SOD/before2.jpg";
import image4 from "../../assets/img/projects/SOD/after2.jpg";

const Sod: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeadingBanner
        heading="SOD"
        onClick={() => {
          navigate(-1);
        }}
        linkHeading
      />
      <MiddleSection>
        <Container>
          <OuterBox>
            <SmallPara
              mb={24}
              text="Sod is our specialty! We love installing new lawns because the impact is immediate! Who doesn't love a new lush, weed free lawn?"
            />

            <Row>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg mb-24"
                  style={{ backgroundImage: `url(${image1})` }}
                >
                  <div className="caption">Before</div>
                </ProjectImage>
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg mb-24"
                  style={{ backgroundImage: `url(${image2})` }}
                >
                  <div className="caption">After</div>
                </ProjectImage>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg mb-24"
                  style={{ backgroundImage: `url(${image3})` }}
                >
                  <div className="caption">Before</div>
                </ProjectImage>
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg mb-24"
                  style={{ backgroundImage: `url(${image4})` }}
                >
                  <div className="caption">After</div>
                </ProjectImage>
              </Col>
            </Row>
          </OuterBox>
          <ContactSection />
        </Container>
      </MiddleSection>
    </>
  );
};

export default Sod;

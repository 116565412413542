import { FC, ReactNode } from "react";
import { Badge } from "react-bootstrap";

interface Props {
  varient?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  children: ReactNode;
}

const CustomBadge: FC<Props> = ({ varient, children }) => {
  return <Badge bg={varient}>{children}</Badge>;
};

export default CustomBadge;

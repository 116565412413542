import styled from "styled-components";
import { OuterBox } from "../../components/CommonStyles";
import aboutUsImage from "../../assets/img/about-us-bg.png";

export const AboutUsOuterBox = styled(OuterBox)`
  p {
    font-family: "Open Sans", sans-serif;
  }
  .description{
    font-size:16px;
  }

  .aboutimg {
    height:350px;
    margin: 0 -48px;
    background-color: #f5f5f5;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${aboutUsImage});
    @media screen and (max-width: 600px) {
      height: 200px;
       }
       @media screen and (max-width: 992px) {
        margin: 0 -24px;
         }
  }

   img {
   width:100%;
  }
  .divider{
    margin:48px 0;
  }
  
`;

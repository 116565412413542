import React, { FC } from 'react'
import Services from '../../components/OurServices/Services'
import MastHead from '../../components/BannerSection/MastHead'
import Info from '../../components/Info/Info'
import Testimonials from '../../components/Testimonials/Testimonials'
import { OurAdvantages } from '../../components/OurAdvantages/OurAdvantages'
import ProjectSlider from '../../components/ProjectsSlider/ProjectSlider'
import ContactSection from '../../components/ContactUs'

const index : FC = () => {
  return (
      <>
      <MastHead />
      <Info />
      <OurAdvantages />
      <Services />
      <Testimonials />
      <ProjectSlider />
      <ContactSection /> 
      </>
  )
}

export default index
import styled from "styled-components";

  export const ProjectImage = styled.div`
    background-position: center; 
    background-repeat: no-repeat; 
    width:100%;
    position:relative;
    background-size: cover;
    border-radius:6px;
  &.longImg{
    height:624px;
    @media screen and (max-width: 600px) {
      height:300px;
      margin-bottom:24px;
    }
  }
  &.normalimg{
    height:450px;
    @media screen and (max-width: 600px) {
      height:300px;
    }
  }
  &.shortImg{
    height:300px;
  }
  &.mb-24{
    margin-bottom:24px;
  }
  .caption{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    text-align: center;
    font-size:24px;
    color: #fff;
    font-weight: 600;
    background: rgba(0, 179, 113,0.8);
    padding: 3px;
    text-transform: capitalize;
}
  }

`
import React from "react";
import { Carousel, Container } from "react-bootstrap";
import styled from "styled-components";
import { LayoutBox } from "../Layout/OuterLayout";
import { H4, H5, Para } from "../Typography";
import previmage from "../../assets/img/prev.svg";
import nextimage from "../../assets/img/next.svg";
import { TestimonailsList } from "../../utils/helper";

const TestimonailsCarousel = styled(Carousel)`
  position: relative;
  padding: 0 220px;
  @media screen and (max-width: 992px) {
    padding: 0 100px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 50px;
  }
  .carousel-control-prev-icon {
    background-image: url(${previmage});
  }
  .carousel-control-next-icon {
    background-image: url(${nextimage});
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 56px;
    height: 56px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 5%;
    @media screen and (max-width: 992px) {
      width: 10%;
    }
  }
  .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 50%;
    opacity: 1;
    background: #b3bac5;
    border: none;
    @media screen and (max-width: 992px) {
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  .carousel-indicators .active {
    background: #1565d8;
  }
  .carousel-indicators {
    bottom: -80px;
  }
  .para {
    min-height: 250px;
    line-height: 49px;
    margin-bottom:32px;
    @media screen and (max-width: 992px) {
      min-height: auto;
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 40px;
    }
  }
`;

const Testimonials = () => {
  return (
    <LayoutBox isBackgroundGrey={true} style={{ paddingBottom: "7rem" }}>
      <Container>
        <TestimonailsCarousel>
          {TestimonailsList.map((item, index) => {
            return (
              <Carousel.Item key={index}>
                <H4 color="#183B56" text={item.title} className="para" />
                <H5 text={item.name} color="#1565D8" />
                <Para text={item.designation} color="#183B56" />
              </Carousel.Item>
            );
          })}
        </TestimonailsCarousel>
      </Container>
    </LayoutBox>
  );
};

export default Testimonials;

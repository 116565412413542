import React from "react";
import { Para } from "../../components/Typography";
import driveImage from "../../assets/img/Our-Drive.jpg";
import { Col, Row } from "react-bootstrap";

const DriveData = () => {
  return (
    <>
      <Para
        mb={24}
        text="We work around the clock to establish a name for ourselves"
      />
      <Para
        mb={24}
        text="We believe strongly that this comes only with hard work and loyalty to our customers. In response to our successes thus far, we continue to strive to meet our goal to expand PPM into every facet of the industry."
      />
      <Para
        mb={24}
        text="Our company strives to grow at a pace that is manageable, but we have a strict policy to not over-extend our client base so to ensure that we can always handle the workload and consistently exceed client expectations. In the beginning years of our now extensive operations, we had concentrated primarily on residential clientele which allowed us to build a base for strong customer service and attention to quality. From residential service delivery, we expanded to condominium and then to small- to mid-size commercial. Today we are primarily servicing large condominium and commercial properties. With the addition several years ago of a handful of substantial contracts from a local regional council, we are proud to have added municipal services to our growing list of expertise."
      />
      <Row className="justify-content-center">
        <Col xs="12" lg="8">
          <img src={driveImage} alt="Our Drive" />
        </Col>
      </Row>
    </>
  );
};

export default DriveData;

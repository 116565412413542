import React, { FC } from "react";
import HeadingBanner from "../../components/TopHeading";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import { H4 } from "../../components/Typography";
import styled from "styled-components";
import { MiddleSection } from "../../components/CommonStyles";
import { Link } from "react-router-dom";
import { projectList } from "../../utils/helper";

const PortfolioContainer = styled(Container)`
  .imagebox {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(255, 255, 255, 0) 99.65%
      ),
      url(3.jpg);
    height: 360px;
    position: relative;
    background-position: center;
    background-size: cover;
    border-radius: 6px;
    border:1px solid #ddd;
    @media screen and (max-width: 600px) {
      height: 280px;
    }
  }
 
  .caption {
    position: absolute;
    bottom:0;
    right: 0;
    left: 0;
    text-align: center;
    text-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
    padding: 60px 5px 20px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  }
`;

const ProjectList: FC = () => {
  return (
    <>
      <HeadingBanner heading="Featured Projects" />
      <MiddleSection>
        <PortfolioContainer>
          <Row className="gy-4 justify-content-center" >
            {projectList.map((item, index) => {
              return (
                <Col xs="12" sm="6" lg="4">
                 
                  <NavLink
                    as={Link}
                    to={item.link}
                    key={index}
                    className="imagebox"
                    style={{ backgroundImage: `url(${item.image})` }}
                  > 
                    <H4 text={item.title} className="caption" color="#fff" />
                   
                  </NavLink>
                </Col>
              );
            })}
          </Row>
        </PortfolioContainer>
      </MiddleSection>
    </>
  );
};

export default ProjectList;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { SmallPara } from "../../components/Typography";
import image1 from "../../assets/img/services/Irrigation/1.jpg";
import image2 from "../../assets/img/services/Irrigation/2.jpg";
import image3 from "../../assets/img/services/Irrigation/3.jpg";
import { ProjectImage } from "../Portfolio/style";

const IrrigationServices = () => {
  return (
    <>
      <SmallPara
        mb={24}
        text="
        The Pristine Property Irrigation Division specializes in servicing commercial, condominium and residential systems. We explore ways to promote water conservation and to ensure the proper watering needs for specific plants. Whether it is installing a new irrigation system or improving existing irrigation systems, the Irrigation Team strives to provide the best possible service."
        color="#424242"
      />

      <Row className="gy-4">
        <Col xs="12" lg="6">
          <ProjectImage
            className="normalimg"
            style={{ backgroundImage: `url(${image2})` }}
          />
        </Col>
        <Col xs="12" lg="6">
          <ProjectImage
            className="normalimg"
            style={{ backgroundImage: `url(${image3})` }}
          />
        </Col>
        <Col xs="12">
          <ProjectImage
            className="normalimg"
            style={{ backgroundImage: `url(${image1})` }}
          />
        </Col>
      </Row>
    </>
  );
};

export default IrrigationServices;

import React, { FC } from "react";
import HeadingBanner from "../../components/TopHeading";
import { MiddleSection } from "../../components/CommonStyles";
import { Col, Container, Row } from "react-bootstrap";
import { H2, H3, Para, SmallHeading } from "../../components/Typography";
import { AboutUsOuterBox } from "./style";
import DriveData from "./DriveData";
import HistoryData from "./HistoryData";
import CrewData from "./CrewData";
import CustomTabs from "../../components/Tabs";

const aboutUSTabs = [
  {
    title: "Our Drive",
    eventKey: "OurDrive",
    tabcontent: <DriveData />,
  },
  {
    title: "Our History",
    eventKey: "OurHistory",
    tabcontent: <HistoryData />,
  },
  {
    title: "Our Crew",
    eventKey: "OurCrew",
    tabcontent: <CrewData />,
  },
];

const index: FC = () => {
  return (
    <>
      <HeadingBanner heading="About Us" />
      <MiddleSection>
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" xl="10">
              <AboutUsOuterBox>
                <Para
                  color="#424242"
                  mb={24}
                  text="Pristine Property Maintenance is a mid-size company that has years of experience providing grounds-keeping and landscape maintenance services throughout the Greater Toronto Area (i.e. Metro Toronto, Scarborough, Don Mills, North York, East York, Vaughan, Markham, York Region, Durham Region, Mississauga, Milton, Brampton). We now have a second location in Brighton Ontario, our Eastern office services areas such as: Kingston, Belleville, Stirling, Campbellford, Brighton and more."
                />
                <Para
                  color="#424242"
                  mb={24}
                  text="Our focus and expertise lies largely in the grounds and snow and ice maintenance for condominium townhomes and municipal properties; however, we also have a wealth of experience in residential, high-rise, and commercial complexes."
                />
                <div className="aboutimg" />
                <H2 mb={48} mt={48} text="Our Mission" />
                <H3 mb={24} text="As a Supplier" />
                <SmallHeading
                  mb={24}
                  className="description"
                  text="It is our goal to be the industry leader by not only being the largest supplier of high quality property maintenance and landscaping services, but by doing so while exceeding the high expectations of our customers. Our success as a provider is highlighted by the enhancements that we bring to the properties in which we work and is achieved by embracing technology and change, communicating effectively, and learning from mistakes."
                />
                <H3 mb={24} text="As an Employer" />
                <SmallHeading
                  mb={24}
                  className="description"
                  text="It is our goal to ensure that our entire team shares in one vision and that we all work together to achieve our full individual and collective potential. In working together, we grow as a team. Mutual respect from and to everyone on our team is not only something we demand but something we promise to return."
                />
                <H3 mb={24} text="As a Vendor" />
                <SmallHeading
                  mb={24}
                  className="description"
                  text="It is our goal to treat our suppliers in the same fashion in which we want to be treated as customers. We want to work only with those vendors who have the same mantra as us – those who aim to treat their people well and be the best at what they do."
                />
                <H3 mb={24} text="As a part of the Community" />
                <SmallHeading
                  mb={24}
                  className="description"
                  text="It is our goal to ensure that we are giving back to the people and organizations in the communities that embrace us and are making our future bright."
                />

                <hr className="divider" />
                <CustomTabs options={aboutUSTabs} />
              </AboutUsOuterBox>
            </Col>
          </Row>
        </Container>
      </MiddleSection>
    </>
  );
};

export default index;

import { FC, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CustomAlert from "../../components/Alert";
import { MiddleSection, OuterBox } from "../../components/CommonStyles";
import HeadingBanner from "../../components/TopHeading";
import { H4 } from "../../components/Typography";
import { jobList, jobListItem } from "../../utils/helper";
import { ReactComponent as AlertImage } from "../../assets/img/Alert.svg";

const JobDetails: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<jobListItem>()

  useEffect(() => {
    const temp = jobList.filter((item) => item.jobId === Number(id))
    if (temp.length > 0) {
      setData(temp?.[0])
    }
    else {
      navigate(`/careers`);
    }
  }, [id, navigate])


  return (
    <>
      <HeadingBanner
        heading="Job Opening"
        onClick={() => {
          navigate(`/careers`);
        }}
        linkHeading
      />
      <MiddleSection>
        <Container>
          <OuterBox>
            <CustomAlert icon={<AlertImage />} variant="danger">
            The hiring process for this position has been completed. Please check back in the future for more opportunities.
            </CustomAlert>
            <H4 text={data?.jobtitle} mb={34} fontweight={600} />
            <div dangerouslySetInnerHTML={{ __html: data?.jobDescription }} style={{ fontFamily: 'Hiragino Mincho Pro' }} />
            <H4 text={`Job Types: ${data?.jobType}`} mb={34} mt={24} />
            {/* <PrimaryButton text="Apply" color="primary" round variant="outlined" /> */}
            <a href="mailto:office@pristineproperty.ca" className="buttonclass" >Apply</a>
          </OuterBox>
        </Container>
      </MiddleSection>
    </>
  );
};

export default JobDetails;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { SmallPara } from "../../components/Typography";
import image1 from "../../assets/img/services/Softscape-Construction/1.png";
import image2 from "../../assets/img/services/8.png";
import { ProjectImage } from "../Portfolio/style";

const SoftscapeConstruction = () => {
  return (
    <>
      <SmallPara
        mb={24}
        text="
        Let PPM bring your landscaping dreams come true. With our experienced softscaping teams, PPM is
        capable of completing many different types of projects. Our crews can complete basic garden
        maintenance, including: edging, weeding, pruning and mulching. We can design new gardens or
        reconfigure existing gardens. If you already have a design plan, we can also execute it for you. We have
        years of experience renovating soils, planting perennials, shrubs and trees to create compelling and
        sustainable gardens. If your plan includes hardscaping features, we will also work alongside our
        construction department to create whatever other elements the design commands.
        "
        color="#424242"
      />
      <Row className="gy-4">
        <Col xs="12" lg="6">
          <ProjectImage
            className="shortImg"
            style={{ backgroundImage: `url(${image1})` }}
          />
        </Col>
        <Col xs="12" lg="6">
          <ProjectImage
            className="shortImg"
            style={{ backgroundImage: `url(${image2})` }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SoftscapeConstruction;
